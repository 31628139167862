var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.loader.active
    ? _c(
        "zg-loader",
        { attrs: { boxed: true, text: _vm.getStatusMessage, fullscreen: "" } },
        [
          _vm.loader.type === "authentication"
            ? _c(
                "template",
                { slot: "buttons" },
                [
                  _c(
                    "zg-button",
                    {
                      attrs: {
                        icons: { right: "close" },
                        "data-name": "cancel-btn",
                        type: "button",
                      },
                      on: { click: _vm.cancelAuthentication },
                    },
                    [_vm._v("\n      " + _vm._s(_vm.content.cancel) + "\n    ")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }