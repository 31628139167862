<template>
  <zg-loader
    v-if="loader.active"
    :boxed="true"
    :text="getStatusMessage"
    fullscreen
  >
    <template
      v-if="loader.type === 'authentication'"
      slot="buttons"
    >
      <zg-button
        :icons="{ right: 'close' }"
        data-name="cancel-btn"
        type="button"
        @click="cancelAuthentication"
      >
        {{ content.cancel }}
      </zg-button>
    </template>
  </zg-loader>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  export default {
    name: 'Loader',
    components: {
      ZgButton: () => import(/* webpackChunkName: 'zc/zg-button' */
        '@zc/components/ZgButton/ZgButton'),
      ZgLoader: () => import(/* webpackChunkName: 'zc/zg-loader' */
        '@zc/components/ZgLoader/ZgLoader')
    },
    computed: {
      ...mapGetters('content', ['getContent']),
      ...mapGetters('loader', ['getLoader']),
      ...mapGetters('messages', ['getStatusMessage']),
      content () {
        return this.getContent?.common ?? {}
      },
      loader () {
        return this.getLoader ?? {}
      }
    },
    methods: {
      ...mapActions('cl/analytics', ['buttonTracking']),
      async cancelAuthentication () {
        await this.buttonTracking({ label: 'cancel-authentication' })

        this.EVENT_BUS.$emit('authentication:cancel')
      }
    }
  }
</script>
